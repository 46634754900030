@import "simplus-material/scss/index";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/////////////////////////////
// CODE
/////////////////////////////
@import '~highlight.js/styles/monokai-sublime.css';
pre {
  margin: 0;
}
